@font-face {
font-family: '__basierCircle_127efa';
src: url(/_next/static/media/23c6d75b0040bfd2-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__basierCircle_127efa';
src: url(/_next/static/media/f761924f41e50581-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__basierCircle_127efa';
src: url(/_next/static/media/a8d28dc7a7b519fd-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__basierCircle_127efa';
src: url(/_next/static/media/ac9fa3287eec0280-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__basierCircle_Fallback_127efa';src: local("Arial");ascent-override: 92.38%;descent-override: 24.98%;line-gap-override: 0.00%;size-adjust: 106.08%
}.__className_127efa {font-family: '__basierCircle_127efa', '__basierCircle_Fallback_127efa'
}.__variable_127efa {--font-basier-circle: '__basierCircle_127efa', '__basierCircle_Fallback_127efa'
}

